import React from 'react';
import logo from './logo.png';
import { withStyles } from '@material-ui/core/styles';
import './App.css';
import Workouts from './container/Workouts'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c80a1e',
    }
  }
});

const styles = theme => ({
  logo: {
    margin: '10px auto 10px',
    display: 'block',
    width: 150
  }
});




class App extends React.Component {



  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <div className="app">
          <img src={logo} className={classes.logo} alt="Crosszone" />
          <Workouts />

        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
