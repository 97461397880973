import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

const styles = (theme) => ({
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 30,
    marginBottom: 30
  },
  loading: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%'
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    background: '#FFFFFF',
    lineHeight: '9px',
    marginLeft: '2px',
    border: '1px solid #CCC',
    outline: 'none',
    '&.active': {
      background: '#04B404',
      border: '1px solid #04B404',
      '& svg': {
        fill: '#FFFFFF'
      }
    },
    '& svg': {
      width: 14
    }
  },
  buttonyellow: {
    background: '#FFFFFF',
    lineHeight: '9px',
    marginLeft: '2px',
    border: '1px solid #CCC',
    outline: 'none',
    '&.active': {
      background: '#FFBF00',
      border: '1px solid #FFBF00',
      '& svg': {
        fill: '#FFFFFF'
      }
    },
    '& svg': {
      width: 14
    }
  },
  buttonred: {
    background: '#FFFFFF',
    lineHeight: '9px',
    marginLeft: '2px',
    border: '1px solid #CCC',
    outline: 'none',
    '&.active': {
      background: '#FF0000',
      border: '1px solid #FF0000',
      '& svg': {
        fill: '#FFFFFF'
      }
    },
    '& svg': {
      width: 14
    }
  },
  input: {
    width: '100%',
    marginBottom: 10
  },
  table: {
    minWidth: 350,
    '& tr': {
      borderLeft: '5px solid #FFFFFF',
      '&.completed': {
        borderLeft: '5px solid #c80a1e'
      },
      '& .info': {
        padding: '5px 0 0 0',
        color: '#999'
      }
    }
  },
  legend: {
    '& span': {
      fontSize: 12,
      marginBottom: 10,
      display: 'block',
      fontWeight: 'bold'
    },
    '& ul': {
      listStyleType: 'none',
      margin: '0 0 20px 0',
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap',

      flexDirection: 'column',
      '& li': {
        fontSize: 12,
        marginRight: 10,
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center',
        '& svg': {
          marginRight: 5,
          width: 14,
          height: 14
        }
      }
    }
  },
  info: {
    fontSize: 12,
    color: '#DDD',
    marginTop: '100'
  },
  logout: {
    width: '200px',
    display: 'block',
    margin: '0 auto'
  },
  passwordforget: {
    margin: '0 0 0 10px'
  }
});

class Workouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      email: '',
      password: '',
      login: 0,
      studio: 0,
      studios: [],
      date: this.getToday(),
      loading: 0,
      passwordforget: 0,
      snackbar: false
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem('loggedAs') && localStorage.getItem('studios')) {
      let studios = JSON.parse(localStorage.getItem('studios'));
      this.setState({ login: 1, studio: studios[0].ID, studios: studios }, () => {
        this.getWorkouts();
      });
    }
  };

  getWorkouts = () => {
    this.setState({ loading: 1 });
    if (this.state.date && this.state.studio) {
      axios
        .post('https://probetraining.zone.fit/wp-json/zone/v1/workouts', {
          date: this.state.date,
          studio: this.state.studio
        })
        .then((response) => {
          const user = response.data.posts.map((elem) => {
            return { ...elem, showInfo: false };
          });

          this.setState({ user: user, loading: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  showInfo = (key) => () => {
    this.setState((state) => {
      return {
        ...state,
        user: state.user.map((elem, index) => {
          if (key === index) {
            return { ...elem, showInfo: !elem.showInfo };
          }
          return elem;
        })
      };
    });
  };

  logout = () => {
    localStorage.clear();
    window.top.location.reload();
  };
  setField = (id, name, fieldValue) => (event) => {
    let user = this.state.user.map((value) => {
      if (value.id === id) {
        return { ...value, [name]: fieldValue };
      }
      return value;
    });
    this.setState({ user: user });
  };

  getToday = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
  };

  login = () => (event) => {
    this.setState({ loading: 1 });
    axios
      .post('https://probetraining.zone.fit/wp-json/zone/v1/login', {
        email: this.state.email,
        password: this.state.password
      })
      .then((response) => {
        if (response.data.status === 0) {
          this.setState({ passwordforget: 1 });
          this.setState({ loading: 0, snackbar: true, snackbarMsg: 'Login failed!' });
        } else {
          if (response.data && response.data.status === 1) {
            this.setState({ login: 1, studio: response.data.studios[0].ID, studios: response.data.studios });
            localStorage.setItem('loggedAs', response.data.loginData.ID);
            localStorage.setItem('studios', JSON.stringify(response.data.studios));
            this.getWorkouts();
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: 0, snackbar: true, snackbarMsg: 'Server down?' });
      });
  };

  completeDay = () => (event) => {
    this.setState({ loading: 1 });
    axios
      .post('https://probetraining.zone.fit/wp-json/zone/v1/completeday', {
        user: this.state.user,
        date: this.state.date,
        studio: this.state.studio
      })
      .then((response) => {
        this.setState({ snackbar: true, snackbarMsg: 'Saved successfully', user: response.data.posts, loading: 0 });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveDay = () => (event) => {
    this.setState({ loading: 1 });
    axios
      .post('https://probetraining.zone.fit/wp-json/zone/v1/saveday', {
        user: this.state.user,
        date: this.state.date,
        studio: this.state.studio
      })
      .then((response) => {
        this.setState({ snackbar: true, snackbarMsg: 'Saved successfully', user: response.data.posts, loading: 0 });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  delete = (id) => (event) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      console.log('delete');
      this.setState({ loading: 1 });
      axios
        .post('https://probetraining.zone.fit/wp-json/zone/v1/deleteentry', {
          id: id,
          date: this.state.date,
          studio: this.state.studio
        })
        .then((response) => {
          this.setState({ snackbar: true, snackbarMsg: 'Entry deleted successfully', user: response.data.posts, loading: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.loading === 1 && <LinearProgress className={classes.loading} />}
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ snackbar: false });
          }}>
          <p>{this.state.snackbarMsg}</p>
        </Snackbar>
        {this.state.login === 0 && (
          <React.Fragment>
            <FormControl className={classes.input}>
              <TextField
                id="email-input"
                label="E-Mail"
                className={classes.input}
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
                margin="dense"
              />
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                id="passwort-input"
                label="Passwort"
                type="password"
                className={classes.input}
                value={this.state.password}
                onChange={(event) => {
                  this.setState({ password: event.target.value });
                }}
                margin="dense"
              />
            </FormControl>
            <Button variant="contained" color="primary" onClick={this.login()}>
              Login
            </Button>
            {this.state.passwordforget === 1 && (
              <Button className={classes.passwordforget} onClick={() => (window.location.href = 'https://probetraining.zone.fit/wp-login.php?action=lostpassword')} variant="contained" color="default">
                Lost your password?
              </Button>
            )}
          </React.Fragment>
        )}

        {this.state.login === 1 && (
          <React.Fragment>
            <FormControl className={classes.input}>
              <InputLabel>Studio</InputLabel>
              <Select
                value={this.state.studio}
                onChange={(event) => {
                  this.setState({ studio: event.target.value }, () => {
                    this.getWorkouts();
                  });
                }}>
                {this.state.studios.map((row) => (
                  <MenuItem key={row.ID} value={row.ID}>
                    {row.post_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                id="date"
                label="Date"
                type="date"
                defaultValue={this.state.date}
                onChange={(event) => {
                  this.setState({ date: event.target.value }, () => {
                    this.getWorkouts();
                  });
                }}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg className="MuiSvgIcon-root MuiSelect-date MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path d="M7 10l5 5 5-5z"></path>
                      </svg>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            {this.state.user.length > 0 ? (
              <React.Fragment>
                <p>Participants: {this.state.user.length}</p>
                <Paper className={classes.paper}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">State</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.user.map((row, index) => (
                        <TableRow key={row.id} className={row.completed === '1' ? 'completed' : null}>
                          <TableCell scope="row">
                            <span onClick={this.showInfo(index)}>
                              {row.firstname} {row.lastname}
                            </span>

                            {row.showInfo && (
                              <div className={'info'}>
                                {row.email} - {row.tel}
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <button className={row.status === 'no-show' ? classes.buttonred + ' active' : classes.buttonred} onClick={this.setField(row.id, 'status', 'no-show')}>
                              <CloseIcon />
                            </button>
                            <button className={row.status === 'show' ? classes.buttonyellow + ' active' : classes.buttonyellow} onClick={this.setField(row.id, 'status', 'show')}>
                              <CheckIcon />
                            </button>
                            <button className={row.status === 'member' ? classes.button + ' active' : classes.button} onClick={this.setField(row.id, 'status', 'member')}>
                              <ThumbUpAltIcon />
                            </button>
                            <button className={classes.button} onClick={this.delete(row.id)}>
                              <DeleteIcon />
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
                {this.state.date <= this.getToday() && (
                  <div className={classes.buttonHolder}>
                    <Button variant="outlined" color="default" onClick={this.saveDay()}>
                      Save
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.completeDay()}>
                      Send Mails *
                    </Button>
                  </div>
                )}

                <p className={classes.info}>* Please be careful! By clicking "Send Mails" the tagged participants will receive an e-mail corresponding to their status.</p>

                <div className={classes.legend}>
                  <span>Legend</span>
                  <ul>
                    <li>
                      <CloseIcon /> not show
                    </li>
                    <li>
                      <CheckIcon /> show
                    </li>
                    <li>
                      <ThumbUpAltIcon /> Member
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : (
              <div>
                <p>No registrations for this day.</p>
              </div>
            )}
            <Button variant="outlined" color="default" onClick={this.logout} className={classes.logout}>
              Logout
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Workouts);
